/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Getter, Mutation, Action, State} from 'vuex-class'
import { Support } from '@/store/support/types'
import { SupportService } from '@/components/pages/footer-pages/support-service/support-service.interface'

const namespace: string = 'support';

export default class SupportServicePage extends Vue {
	@State('support') support: Support | undefined;
	@Action('storeSupportRequest', {namespace}) storeSupportRequest: any;

	supportService: SupportService = {
		username: '',
		email: '',
		phone: '',
		message: ''
	}

	onSubmit() {
		this.storeSupportRequest({
			name: this.supportService.username,
			email: this.supportService.email,
			phone: this.supportService.phone,
			description: this.supportService.message,
		})
		this.supportService = {
			username: '',
			email: '',
			phone: '',
			message: ''
		}
	}
}
